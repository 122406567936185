import styled from 'styled-components'

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Option = styled.div`
  cursor: pointer;
  user-select: none;
  font-size: 20px;
  padding: 0 3px;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 200vw;
  height: 100vh;
  margin-left: -100%;
`;